import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css';

import Home from './routes/home/home';
import Cards from './routes/cards/cards';
import NotFound from './routes/not-found/not-found';

import './App.scss'

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/card/:key" element={<Cards />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
