import React from 'react';
import { PathRouteProps, useParams } from 'react-router';

import NotFound from '../not-found/not-found';

import background from './img/bg.jpg';
import photoAsh from './img/photo-ash.jpg';
import photoSnd from './img/photo-snd.jpg';

import './cards.scss';

interface Cards {
  [key: string]: {
    name: string
    position: string
    phone: string,
    phoneNumber: string,
    email: string,
    background: string,
    photo: string
  }
}

const cards: Cards = {
  'ash': {
    name: 'Ashley Brown',
    position: 'Software Engineer',
    phone: '+44(0) 7980214838',
    phoneNumber: '+447980214838',
    email: 'contact@bedican.co.uk',
    background: background,
    photo: photoAsh
  },
  'snd': {
    name: 'Sandryn Brown',
    position: 'Customer Success Manager',
    phone: '+44(0) 7894135547',
    phoneNumber: '+447894135547',
    email: 'contact@bedican.co.uk',
    background: background,
    photo: photoSnd
  }
};

interface CardsRouteProps extends PathRouteProps {
}

const CardsRoute: React.FC<CardsRouteProps> = (props: CardsRouteProps) => {

  const key = useParams<{key: string}>().key;

  if ((!key) || (!cards[key])) {
    return <NotFound />
  }

  const card = cards[key];

  return (
    <React.Fragment>
      <main>
        <div className="business-card">
          <div className="header">
            <div className="background"><img src={card.background} alt="background" /></div>
            <div className="photo"><img src={card.photo} alt={card.name} /></div>
          </div>
          <div className="name">{card.name}</div>
          <div className="details">
            <div className="position"><i className="bi bi-person-fill card-icon"></i>{card.position}</div>
            <div className="company"><i className="bi bi-building card-icon"></i>Bedican Ltd</div>
            <div className="Location"><i className="bi bi-geo-alt-fill card-icon"></i>Greater London, UK</div>
            <div className="phone"><i className="bi bi-telephone-fill card-icon"></i><a href={`tel:${card.phoneNumber}`}>{card.phone}</a></div>
            <div className="www"><i className="bi bi-browser-chrome card-icon"></i><a href="/">www.bedican.co.uk</a></div>
            <div className="email"><i className="bi bi-envelope-fill card-icon"></i><a href={`mailto:${card.email}`}>{card.email}</a></div>
          </div>
          <div className="vcf">
            <a href={`/vcard/${key}.vcf`}><i className="bi bi-download card-icon"></i>Download vCard.</a>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default CardsRoute;