import React, { RefObject, forwardRef } from 'react';

import { HeaderRef } from '../header/header';

import bgImage from './img/bg.png';
import './process.scss';

interface ProcessProps {
  headerRef: RefObject<HeaderRef>
}

const Process = forwardRef<HTMLElement, ProcessProps>((props: ProcessProps, ref: React.ForwardedRef<HTMLElement>) => {
  return (
    <section ref={ref} className="component-process" style={{backgroundImage: `linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)), url(${bgImage})`}}>
      <div className="container" data-aos="zoom-in">

        <div className="row">
          <div className="col-lg-12 text-center text-lg-start">
            <h2>How we work</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-9 text-lg-start">
            <p>Our process is an agile journey comprising of four stages: <span>Discovery</span>, <span>Proposal</span>, <span>Implementation</span> and <span>Evaluation</span>.</p>
            <ul>
              <li>
                <span>01. Discovery</span>
                <p>We begin by understanding your objectives and work with you to refine requirements.</p>
              </li>
              <li>
                <span>02. Proposal</span>
                <p>Once understanding the objectives we propose the infrastructure, systems or processes to achieve your goals.</p>
              </li>
              <li>
                <span>03. Implementation</span>
                <p>We implement the proposal in an agile process, iterating over the product to meet the requirements.</p>
              </li>
              <li>
                <span>04. Evaluation</span>
                <p>Ongoing evaluation and feedback is performed throughout, including how the product can be supported after achieving the objectives.</p>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 text-center align-middle">
            <a href="#contact" className="cta" onClick={() => {
                props.headerRef.current?.clickCta();
              }}>Contact Us</a>
          </div>
        </div>

      </div>
    </section>
  );
});

export default Process;